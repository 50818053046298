import { useCallback } from 'react'
import { useApolloClient, useMutation } from 'apollo-client'
import { GraphQLError, UserError } from 'helpers'

import { useResetCouponsCache } from './useCouponData'
import couponApplyQuery, { type CouponApplyVariables } from './graph/couponApply.graphql'


// lightweight variant for applying coupon on payment page
export const useApplyCouponCallback = () => {
  const apolloClient = useApolloClient()
  const resetCouponsCache = useResetCouponsCache()

  return useCallback(async (input: CouponApplyVariables['input']) => {
    const result = await apolloClient.mutate({
      mutation: couponApplyQuery,
      variables: {
        input,
        validateOnly: false,
        gender: null,
      },
      fetchPolicy: 'no-cache',
    })

    resetCouponsCache()

    // evict applied coupon cache for the cart
    apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'purchaseInquiry:InquireSubscriptionCoupon' })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.couponApply

    if (error) {
      throw new UserError(error)
    }

    return data
  }, [ apolloClient, resetCouponsCache ])
}

export const useApplyCoupon = () => {
  const [ mutate, { isFetching, client: apolloClient } ] = useMutation(couponApplyQuery, {
    fetchPolicy: 'no-cache',
  })

  const resetCouponsCache = useResetCouponsCache()

  const submit = useCallback(async (input: CouponApplyVariables['input'], refetchQueries?: string[]) => {
    const result = await mutate({
      variables: {
        input,
        validateOnly: false,
        gender: null,
      },
      refetchQueries,
    })

    resetCouponsCache()

    // evict applied coupon cache for the cart
    apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'purchaseInquiry:InquireSubscriptionCoupon' })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.couponApply

    if (error) {
      throw new UserError(error)
    }

    return data
  }, [ mutate, resetCouponsCache, apolloClient ])

  return [
    submit,
    {
      isFetching,
    },
  ] as const
}
