import { useCallback, useEffect } from 'react'
import { useApolloClient, makeVar, useReactiveVar } from 'apollo-client'
import logger from 'logger'
import { GraphQLError } from 'helpers'
import { useUser } from 'modules/user'
import type { CouponApplyPeriod, OriginType } from 'typings/graphql'

import couponApplyQuery, { type CouponApplyPayload } from './graph/couponApply.graphql'


export type FreeProduct = CouponApplyPayload['couponApply']['data']['coupon']['freeProduct']

type UseCouponDataProps = {
  couponCode?: string
  origin?: OriginType
}

export type CouponApplyResult = CouponApplyPayload['couponApply']['data']['coupon'] & {
  totalAmount: number
  couponApplyPeriod: CouponApplyPeriod
}

const modifyResult = (data: CouponApplyPayload['couponApply']['data']): CouponApplyResult => {
  if (!data) {
    return null
  }

  const { coupon, totalAmount, couponApplyPeriod } = data

  return {
    ...coupon,
    totalAmount,
    couponApplyPeriod,
  }
}

// empty object or undefined means the value is fetching, null means it's fetched but not available
const couponDataVar = makeVar<Record<string, { data: CouponApplyResult }>>({})

export const useResetCouponsCache = () => {
  return useCallback(() => {
    couponDataVar({})
  }, [])
}

// ATTN this hook is for reading-only
const useCouponData = ({ couponCode: userCouponCode, origin }: UseCouponDataProps = {}) => {
  const { gender } = useUser()
  const apolloClient = useApolloClient()
  const couponDataCache = useReactiveVar(couponDataVar)

  const couponCode = userCouponCode?.toUpperCase()
  const cacheKey = couponCode ? `COUPON-APPLY-${couponCode}-${gender}` : null

  const rawCouponData = couponDataCache[cacheKey]
  const isDataAvailable = Boolean(rawCouponData) // required to refetch after data reset
  const couponData = rawCouponData?.data
  const isCouponDataFetching = cacheKey ? couponData === undefined : false
  const isCouponUnavailable = cacheKey ? couponData === null : false

  useEffect(() => {
    const fetchCouponData = async () => {
      try {
        if (!couponCode) {
          return
        }

        const result = await apolloClient.mutate({
          mutation: couponApplyQuery,
          variables: {
            input: {
              couponCode,
              origin,
              validateOnly: true,
            },
            gender,
            validateOnly: true,
          },
          fetchPolicy: 'no-cache',
        })

        if (result.errors) {
          throw new GraphQLError(result.errors)
        }

        const { data } = result.data.couponApply

        return modifyResult(data)
      }
      catch (error) {
        logger.info(error)
      }
    }

    if (!cacheKey) {
      return
    }

    const lastCacheValue = couponDataVar()

    if (!lastCacheValue[cacheKey]) {
      // data is undefined, but promise is fired
      couponDataVar({
        ...lastCacheValue,
        [cacheKey]: { data: undefined },
      })

      fetchCouponData()
        .then((data) => {
          couponDataVar({
            ...couponDataVar(),
            [cacheKey]: { data },
          })
        })
        .catch(() => {
          couponDataVar({
            ...couponDataVar(),
            // reset cache
            [cacheKey]: undefined,
          })
        })
    }
  }, [ cacheKey, origin, couponCode, apolloClient, gender, isDataAvailable ])

  return {
    couponData,
    isCouponDataFetching,
    isCouponUnavailable,
  }
}


export default useCouponData
